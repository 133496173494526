import {
  Heading,
  ListItem,
  LocateIcon,
  majorScale,
  minorScale,
  Pane,
  Paragraph,
  Text,
  UnorderedList,
  MapMarkerIcon,
  InfoSignIcon,
  Tooltip,
} from 'evergreen-ui';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import {
  formatDateToOwnFormatWl,
  formatEventTimeZone,
  getFullAddress,
  getMinDates,
} from '../../../helpers/functions';
import {
  DRESS_CODE,
  APPROVAL_TYPE,
  VIEWPORT_BREAKPOINTS,
} from '../../../helpers/enums';
import {
  EventBanner,
  EventBannerWithContainer,
} from '../../../components/event-banner/event-banner';
import Header from '../../../components/header/header';
import Container from '../../../components/container/container';
import { ReactComponent as DateIcon } from '../../../assets/icons/calendar.svg';
import { ReactComponent as VenueIcon } from '../../../assets/icons/location-marker.svg';
import { ReactComponent as ClockIcon } from '../../../assets/icons/clock.svg';
import { authUserVar } from '../../../helpers/auth';
import Btn from '../../../components/btn/btn';

function EventDetailsHeader({
  isLastStep,
  event,
  hideHeader,
  eventId,
  setIsShown,
  hasSaleEnded,
  externalTicketSellerLinks,
  ticketsSoldOut,
  hostBuyingFlow,
}) {
  const {
    coverImageUrl,
    EventTitle,
    EventDates,
    Venue,
    PromoterApprovalType,
    CompanyId,
    EventPromoters,
    isPromoterIdIncluded,
    eventDressCodeTerms,
    hatAcceptedDescription,
    optionAgeDescription,
    optionDressCodeDescription,
    optionIdRequirementDescription,
    shoeTypeDescription,
  } = event;

  const authUser = authUserVar();

  //EVENT DETAILS CRITERIA
  const {
    OptionAge,
    OptionLastEntry,
    OptionDressCode,
    OptionIdRequirement,
    HatAccepted,
    ShoeType,
    isHiddenVenue,
  } = event;

  const isLaptop = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.lg}px)`,
  });

  const isTabletMd = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.md}px)`,
  });

  if (isLastStep) return <SuccessHeader />;

  const age = (number) => {
    if (number >= 1 && number <= 100) {
      return `${number}+`;
    }

    return 'Not Applicable';
  };

  const { minEventStartDate, maxEventEndDate } = getMinDates(EventDates, Venue);

  return (
    <>
      {isTabletMd ? (
        <>
          {!hideHeader && !hostBuyingFlow && (
            <Pane backgroundColor="var(--primary-default-color)">
              <Container maxWidth="100%" paddingY={majorScale(1)}>
                <Header />
              </Container>
            </Pane>
          )}

          <EventBannerWithContainer image={coverImageUrl}>
            <Pane
              paddingX={minorScale(5)}
              paddingY={majorScale(8)}
              height="inherit"
              display="flex"
              flexDirection="column"
              color="#fff"
              fontSize="1rem"
            >
              <Heading
                is="h1"
                marginTop="auto"
                marginBottom={minorScale(4)}
                color="inherit"
                fontSize="1.85em"
                fontWeight={900}
                wordBreak="break-all"
              >
                {EventTitle}
              </Heading>
              {!isEmpty(EventDates) && (
                <>
                  <Pane display="flex" alignItems="center">
                    <Paragraph
                      marginBottom={minorScale(3)}
                      marginRight={majorScale(2)}
                      display="flex"
                      alignItems="center"
                      color="inherit"
                      fontSize="1.125em"
                      width="153px"
                    >
                      <DateIcon className="mr-2" />
                      Start Date
                    </Paragraph>

                    <Paragraph
                      color="inherit"
                      marginBottom={minorScale(3)}
                      fontSize="1.225em"
                    >
                      {formatDateToOwnFormatWl(
                        minEventStartDate,
                        "d LLL yyyy',' T"
                      )}
                    </Paragraph>
                  </Pane>

                  <Pane display="flex" alignItems="center">
                    <Paragraph
                      marginBottom={minorScale(3)}
                      marginRight={majorScale(2)}
                      display="flex"
                      alignItems="center"
                      color="inherit"
                      fontSize="1.125em"
                      width="153px"
                    >
                      <DateIcon className="mr-2" />
                      End Date
                    </Paragraph>

                    <Paragraph
                      color="inherit"
                      marginBottom={minorScale(3)}
                      fontSize="1.225em"
                    >
                      {formatDateToOwnFormatWl(
                        maxEventEndDate,
                        "d LLL yyyy',' T"
                      )}
                    </Paragraph>
                  </Pane>
                  {/* {minLastEntryTime && (
                    <Pane display="flex" alignItems="center">
                      <Paragraph
                        marginBottom={minorScale(3)}
                        marginRight={majorScale(2)}
                        display="flex"
                        alignItems="center"
                        color="inherit"
                        fontSize="1.125em"
                        width="153px"
                      >
                        <ClockIcon className="mr-2" />
                        Gate Closes
                      </Paragraph>

                      <Paragraph
                        color="inherit"
                        marginBottom={minorScale(3)}
                        fontSize="1.225em"
                      >
                        {formatDateToOwnFormatWl(
                          minLastEntryTime,
                          "d LLL yyyy',' T"
                        )}
                      </Paragraph>
                    </Pane>
                  )} */}
                </>
              )}

              <>
                <Paragraph
                  marginBottom={minorScale(3)}
                  display="flex"
                  alignItems="center"
                  color="inherit"
                  fontSize="1.225em"
                >
                  <LocateIcon className="mr-2" />
                  {isHiddenVenue
                    ? 'Hidden Venue'
                    : Venue?.VenueTitle?.split(',')?.[0]}
                </Paragraph>
                <Paragraph
                  marginBottom={minorScale(3)}
                  display="flex"
                  alignItems="center"
                  color="inherit"
                  fontSize="1.225em"
                >
                  <Pane maxWidth="16px" width="100%" className="mr-2">
                    <MapMarkerIcon />
                  </Pane>
                  {isHiddenVenue
                    ? Venue?.city
                    : getFullAddress(
                        Venue?.VenueTitle,
                        Venue?.venueDescription,
                        Venue?.AddressLine1,
                        Venue?.addressLine2,
                        Venue?.city,
                        Venue?.postCode,
                        Venue?.Country?.countryDescription
                      )}
                </Paragraph>
              </>
            </Pane>
          </EventBannerWithContainer>
        </>
      ) : (
        <>
          {!hostBuyingFlow && (
            <Pane backgroundColor="var(--primary-default-color)">
              <Container
                paddingTop={minorScale(3)}
                paddingBottom={minorScale(5)}
              >
                <Header />
              </Container>
            </Pane>
          )}

          <EventBanner image={coverImageUrl} />

          <Pane
            paddingX={minorScale(5)}
            paddingTop={minorScale(5)}
            display="flex"
            flexDirection="column"
            color="var(--black-light-color)"
            fontSize="1rem"
          >
            <Heading
              is="h1"
              marginTop="auto"
              marginBottom={minorScale(4)}
              color="inherit"
              fontSize="1.5rem"
              fontWeight={900}
            >
              {EventTitle}
            </Heading>

            {!isEmpty(EventDates) && (
              <Pane display="flex" alignItems="center">
                <Paragraph
                  marginBottom={minorScale(3)}
                  marginRight={majorScale(2)}
                  display="flex"
                  alignItems="center"
                  color="inherit"
                  fontSize="1.125em"
                  width="153px"
                >
                  <DateIcon className="mr-2" />
                  Start Date
                </Paragraph>

                <Paragraph
                  color="inherit"
                  marginBottom={minorScale(3)}
                  fontSize="1.225em"
                >
                  {formatDateToOwnFormatWl(
                    minEventStartDate,
                    "d LLL yyyy',' T"
                  )}
                </Paragraph>
              </Pane>
            )}

            {!isEmpty(EventDates) && (
              <Pane display="flex" alignItems="center">
                <Paragraph
                  marginBottom={minorScale(3)}
                  marginRight={majorScale(2)}
                  display="flex"
                  alignItems="center"
                  color="inherit"
                  fontSize="1.125em"
                  width="153px"
                >
                  <DateIcon className="mr-2" />
                  End Date
                </Paragraph>

                <Paragraph
                  color="inherit"
                  marginBottom={minorScale(3)}
                  fontSize="1.225em"
                >
                  {formatDateToOwnFormatWl(maxEventEndDate, "d LLL yyyy',' T")}
                </Paragraph>
              </Pane>
            )}
            {/* {!isEmpty(EventDates) && (
              <>
                {minLastEntryTime && (
                  <Pane display="flex" alignItems="center">
                    <Paragraph
                      marginBottom={minorScale(3)}
                      marginRight={majorScale(2)}
                      display="flex"
                      alignItems="center"
                      color="inherit"
                      fontSize="1.125em"
                      width="153px"
                    >
                      <ClockIcon className="mr-2" />
                      Gate Closes
                    </Paragraph>

                    <Paragraph
                      color="inherit"
                      marginBottom={minorScale(3)}
                      fontSize="1.225em"
                    >
                      {formatDateToOwnFormatWl(
                        minLastEntryTime,
                        "d LLL yyyy',' T"
                      )}
                    </Paragraph>
                  </Pane>
                )}
              </>
            )} */}
            <>
              <Paragraph
                marginBottom={minorScale(3)}
                display="flex"
                alignItems="center"
                color="inherit"
                fontSize="1.225em"
                columnGap={minorScale(3)}
              >
                <LocateIcon />
                {isHiddenVenue
                  ? 'Hidden Venue'
                  : Venue?.VenueTitle?.split(',')?.[0]}
              </Paragraph>
              <Paragraph
                marginBottom={minorScale(3)}
                display="flex"
                alignItems="center"
                color="inherit"
                fontSize="1.225em"
                columnGap={minorScale(3)}
              >
                <Pane maxWidth="16px" width="100%">
                  <MapMarkerIcon />
                </Pane>
                {isHiddenVenue
                  ? Venue?.city
                  : getFullAddress(
                      Venue?.VenueTitle,
                      Venue?.venueDescription,
                      Venue?.AddressLine1,
                      Venue?.addressLine2,
                      Venue?.city,
                      Venue?.postCode,
                      Venue?.Country?.countryDescription
                    )}
              </Paragraph>
            </>
          </Pane>
        </>
      )}

      {isLaptop && (
        <Container
          is="section"
          maxWidth="100%"
          paddingY={minorScale(4)}
          display="flex"
          alignItems="baseline"
          justifyContent="center"
          color="var(--black-light-color)"
          fontSize="1rem"
        >
          {authUser?.hostId?.toLowerCase() !== CompanyId?.toLowerCase() &&
          !hasSaleEnded &&
          !ticketsSoldOut &&
          !isPromoterIdIncluded &&
          (PromoterApprovalType === APPROVAL_TYPE.AUTOAPPROVE ||
            PromoterApprovalType === APPROVAL_TYPE.MANUALAPPROVAL) ? (
            <Btn
              fontSize="1em"
              padding={isLaptop ? '10px' : '3px'}
              marginRight={isLaptop ? '20px' : '10px'}
              look="primary-outlined"
              onClick={() => setIsShown(true)}
              width="150px"
            >
              Promote event
            </Btn>
          ) : (
            ''
          )}

          <Heading
            color="inherit"
            fontWeight={700}
            fontSize="1em"
            className="mr-3"
          >
            Instructions:
          </Heading>

          <UnorderedList
            display="flex"
            alignItems="center"
            className="ml-1"
            flexWrap="wrap"
          >
            {OptionAge && (
              <ListItem
                color="inherit"
                fontSize="1em"
                className="mr-4"
                display="flex"
                alignItems="center"
              >
                <Text
                  className="mr-2"
                  fontWeight={700}
                  color="inherit"
                  fontSize="inherit"
                >
                  Age:
                </Text>

                {optionAgeDescription ? (
                  <>
                    <Tooltip
                      content={optionAgeDescription}
                      position="bottom-right"
                    >
                      <Pane display="inherit">
                        <InfoSignIcon />
                      </Pane>
                    </Tooltip>
                  </>
                ) : (
                  age(OptionAge)
                )}
              </ListItem>
            )}

            {OptionLastEntry && Date.parse(OptionLastEntry) ? (
              <ListItem color="inherit" fontSize="1em" className="mr-4">
                <Text
                  className="mr-2"
                  fontWeight={700}
                  color="inherit"
                  fontSize="inherit"
                >
                  Last entry:
                </Text>

                {formatEventTimeZone(OptionLastEntry)}
              </ListItem>
            ) : (
              ''
            )}

            {ShoeType ? (
              <ListItem
                color="inherit"
                fontSize="1em"
                className="mr-4"
                display="flex"
                alignItems="center"
              >
                <Text
                  className="mr-2"
                  fontWeight={700}
                  color="inherit"
                  fontSize="inherit"
                >
                  Shoe Type:
                </Text>

                {shoeTypeDescription ? (
                  <>
                    <Tooltip
                      content={shoeTypeDescription}
                      position="bottom-right"
                    >
                      <Pane display="inherit">
                        <InfoSignIcon />
                      </Pane>
                    </Tooltip>
                  </>
                ) : (
                  ShoeType
                )}
              </ListItem>
            ) : (
              ''
            )}

            {OptionDressCode && (
              <ListItem
                color="inherit"
                fontSize="1em"
                className="mr-4"
                display="flex"
                alignItems="center"
              >
                <Text
                  className="mr-2"
                  fontWeight={700}
                  color="inherit"
                  fontSize="inherit"
                >
                  Dress code:
                </Text>

                {optionDressCodeDescription ? (
                  <>
                    <Tooltip
                      content={optionDressCodeDescription}
                      position="bottom-right"
                    >
                      <Pane display="inherit">
                        <InfoSignIcon />
                      </Pane>
                    </Tooltip>
                  </>
                ) : (
                  DRESS_CODE[OptionDressCode]?.label
                )}
              </ListItem>
            )}

            {OptionIdRequirement && (
              <ListItem
                color="inherit"
                fontSize="1em"
                className="mr-4"
                display="flex"
                alignItems="center"
              >
                <Text
                  className="mr-2"
                  fontWeight={700}
                  color="inherit"
                  fontSize="inherit"
                >
                  ID required:
                </Text>

                {optionIdRequirementDescription ? (
                  <>
                    <Tooltip
                      content={optionIdRequirementDescription}
                      position="bottom-right"
                    >
                      <Pane display="inherit">
                        <InfoSignIcon />
                      </Pane>
                    </Tooltip>
                  </>
                ) : OptionIdRequirement === 'YES' ? (
                  'Yes'
                ) : (
                  'No'
                )}
              </ListItem>
            )}

            {HatAccepted && (
              <ListItem
                color="inherit"
                fontSize="1em"
                className="mr-4"
                display="flex"
                alignItems="center"
              >
                <Text
                  className="mr-2"
                  fontWeight={700}
                  color="inherit"
                  fontSize="inherit"
                >
                  Hat accepted:
                </Text>

                {hatAcceptedDescription ? (
                  <>
                    <Tooltip
                      content={hatAcceptedDescription}
                      position="bottom-right"
                    >
                      <Pane display="inherit">
                        <InfoSignIcon />
                      </Pane>
                    </Tooltip>
                  </>
                ) : HatAccepted ? (
                  'Yes'
                ) : (
                  'No'
                )}
              </ListItem>
            )}
            {!isEmpty(eventDressCodeTerms) && (
              <>
                {map(eventDressCodeTerms, (item, index) => (
                  <ListItem
                    key={index}
                    color="inherit"
                    fontSize="1em"
                    className="mr-4"
                    display="flex"
                    alignItems="center"
                  >
                    <Text
                      className="mr-2"
                      fontWeight={700}
                      color="inherit"
                      fontSize="inherit"
                    >
                      {_.get(item, 'fieldTitle')}:
                    </Text>
                    <Tooltip
                      content={_.get(item, 'fieldDesctiption')}
                      position="bottom-right"
                    >
                      <Pane display="inherit">
                        <InfoSignIcon />
                      </Pane>
                    </Tooltip>
                  </ListItem>
                ))}
              </>
            )}
          </UnorderedList>
        </Container>
      )}
    </>
  );
}

const SuccessHeader = () => {
  return (
    <>
      <Pane backgroundColor="var(--primary-default-color)">
        <Container maxWidth="100%" paddingY={majorScale(1)}>
          <Header />
        </Container>
      </Pane>
      <Pane is="header" position="relative" width="100%">
        <Pane
          display="flex"
          flexDirection="column"
          justifyContent="center"
          textAlign="center"
          flexWrap="nowrap"
          rowGap=""
          alignItems="center"
          minHeight="180px"
          boxShadow="inset 0px -1px 0px rgba(0, 0, 0, 0.25)"
          background="radial-gradient(425.22% 714.98% at -39.47% 63.95%, rgba(141, 14, 186, 0.88) 6.9%, #C10755 98.11%)"
        >
          <Pane paddingX="30px" paddingY="10px">
            <Heading
              is="h2"
              fontWeight={700}
              color="#fff"
              fontSize="1.75em"
              marginY="15px"
            >
              Registration Complete
            </Heading>
            <Paragraph fontSize="16px" color="#fff">
              Thank you for registering your interest. <br />
              <Paragraph fontSize="16px" color="#fff" fontWeight={700}>
                We will notify you about this event.
              </Paragraph>
            </Paragraph>
          </Pane>
        </Pane>
      </Pane>
    </>
  );
};
export default EventDetailsHeader;
